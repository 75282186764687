<template>
    <div class="page-block dark logo-block">
        <Suspense><BurgerMenu/></Suspense>
        <div class="logo">
            <router-link :to="{ name: 'home', params: { lang: $route.params.lang}}">
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 595.28 178.49" style="enable-background:new 0 0 595.28 178.49;">
                    <g>
                        <path d="M369.05,88.63c-3.97-4.91-12.59-15.96-18.43-23.91c-0.56-0.8-1.17-1.63-1.76-2.44c-1.09-1.5-2.52-3.45-3.05-4.46
                                c3.39-1.16,12.73-4.78,20.94-9.95c9.59-6.04,14.69-13.73,14-21.11c-0.95-10.23-14.1-15.29-26.1-16.62
                                c-4.12-0.45-12.27-0.75-20.76-0.75c-9.5,0-32.92,0.4-45.29,4.06c-0.22,0.07-0.41,0.21-0.54,0.39c-2.66,3.89-4.35,8.94-5.16,15.42
                                c-0.04,0.31,0.07,0.62,0.3,0.84c0.23,0.22,0.55,0.32,0.85,0.27c25.66-4.17,43.26-5.05,53.5-5.05c17.89,0,23.28,2.58,23.71,4.04
                                c-0.48,1.91-8.8,7.8-23.3,11.14c-0.64-1.86-1.82-5.17-5.08-5.17h0c-2.38-0.14-5.7,1.01-8.88,2.2c-6.44,2.37-7.8,3.31-7.93,3.41
                                c-0.15,0.11-0.27,0.26-0.34,0.44c-0.04,0.09-0.88,2.24-1.38,3.76c-0.09,0.26-0.3,0.64-1.14,0.87l-2.1,0.56
                                c-1.55,0.39-2.59,1.49-2.92,2.01c-0.61,0.95-7.21,13.06-5.6,15.27c0.03,0.04,0.06,0.07,0.09,0.11c0.37,0.38,0.9,0.56,1.62,0.56
                                c0.55,0,1.17-0.1,1.77-0.2c0.37-0.06,0.74-0.12,1.04-0.15c-0.11,0.75-0.54,2.62-2.21,7.1c-4.45,12.72-9.89,29.36-10.54,36.37
                                c-0.01,0.13,0,0.26,0.04,0.38c0.84,2.8,2.17,5.07,3.46,7.26c0.37,0.63,0.75,1.27,1.12,1.93c0.02,0.04,0.05,0.09,0.08,0.13
                                c0.13,0.16,0.3,0.4,0.51,0.68c2.68,3.61,4.67,5.84,6.32,5.84c0.38,0,0.75-0.12,1.05-0.35c0.16-0.12,0.28-0.29,0.35-0.48
                                c3.04-9.09,8.23-23.18,9.47-26.42c0.46-1.19,1.59-4.61,2.91-8.56c1.87-5.62,4.85-14.58,5.99-17.28c1.71,1.82,5.54,7.15,9.25,12.32
                                c3.72,5.18,7.58,10.54,10.6,14.29c2.09,2.55,4.14,5.13,6.2,7.71c5.03,6.31,10.23,12.83,15.81,18.8c0.1,0.1,0.21,0.18,0.34,0.24
                                c4.18,1.79,7.35,2.46,11.74,3.07c0.04,0.01,0.13,0.01,0.18,0.01c0.11,0,10.91-0.51,11.89-3.7c0.03-0.1,0.04-0.19,0.04-0.29
                                c0-3.03-6.69-12.62-10.67-18.05C377.58,100.15,372.43,93.14,369.05,88.63z"/>
                        <path d="M563.52,45.67c-0.29-0.55-1-1.21-2.74-1.21c-0.1,0-0.2,0-0.31,0.01c-19.53,0.96-35.61,2.81-52.63,4.77
                                c-10.18,1.17-20.71,2.38-32.18,3.41c-8.19-43.98-10.04-44.39-11-44.6c-0.08-0.01-0.18-0.02-0.3-0.02c-0.86,0-2.79,0.43-3.72,0.89
                                c-6.87,3.11-15.03,8.92-17.54,10.86c-0.5,0.33-0.95,1.21-6.01,12.49c-4.32,9.61-12.31,27.4-14.16,29.03
                                c-4.02,1.04-7.88,1.64-13.2,2.48c-2.41,0.38-5.12,0.8-8.26,1.34c-4.32,0.8-9.45,14.34-8.96,19.6c0.25,2.67,1.81,2.96,2.47,2.96
                                c0.14,0,0.3-0.01,0.46-0.04c3.98-0.66,14.01-2.47,16.66-3.05c-1.75,3.09-8.36,14.96-12.71,23.36c-0.16,0.3-0.15,0.66,0.02,0.96
                                c0.1,0.18,10.29,17.86,13.14,17.86c1.18-0.03,1.95-0.92,10.37-19.52c1.01-2.22,1.96-4.32,2.77-6.08l0.5-1.11
                                c6.68-14.86,9.16-18.99,10.04-19.73l0.88-0.15c5.48-0.94,15.4-2.64,20.26-3.1c1.67,10.26,2.43,17.87,3.24,25.91
                                c0.43,4.25,0.87,8.64,1.46,13.71c0.02,0.17,0.08,0.33,0.18,0.46c1.57,2.19,3.67,3.79,5.71,5.34c1.29,0.98,2.5,1.9,3.66,3.02
                                c0.18,0.15,0.43,0.36,0.74,0.62c3.95,3.33,7.9,6.42,10.03,6.42c0.61,0,1.01-0.23,1.23-0.43c0.13-0.11,0.23-0.26,0.29-0.42
                                c0.94-2.59-4.44-55.73-4.51-56.21c-0.28-1.82-0.1-2.04,0.08-2.11c1.69-0.38,14.73-2.43,26.26-4.24c9.34-1.47,18.17-2.86,21.3-3.4
                                c0.04-0.01,0.09-0.02,0.13-0.03c6.59-2.07,15.07-6.35,19.47-8.68c1.38-0.62,15.34-7.62,16.77-9.57l0,0
                                C563.76,46.92,563.81,46.23,563.52,45.67z M446.64,57.32c1.55-3.86,5.41-13.38,6.78-16.24l0.01,0c0.67,2.9,1.62,11.88,1.93,14.81
                                L446.64,57.32z"/>
                        <path d="M271.44,16.02c-0.54-0.35-1.21-0.52-1.98-0.52c-0.86,0-1.72,0.21-2.52,0.41c-2.43,0.71-9.65,3.86-11.71,5.1
                                c-0.16,0.09-0.29,0.23-0.37,0.4c-4.98,9.58-9.26,20.07-13.4,30.22c-1.2,2.94-2.4,5.88-3.62,8.81c-1.29,3.25-2.6,6.49-3.91,9.72
                                c-4,9.88-8.14,20.1-11.59,30.78c-0.06,0.18-0.06,0.36-0.02,0.54c0.88,3.62,2.18,6.85,3.43,9.98c0.54,1.36,1.08,2.71,1.63,4.18
                                c0.4,0.84,0.77,1.71,1.13,2.58c1.55,3.66,3.16,7.45,6.79,9.36c0.14,0.08,0.3,0.12,0.46,0.12c0.1,0,0.21-0.02,0.31-0.05
                                c0.26-0.08,0.47-0.26,0.59-0.5c6.54-13.06,11.41-28.07,16.11-42.59c1.13-3.49,2.26-6.99,3.42-10.48c2.77-8.91,5.63-18.32,8.5-28
                                c1.23-4.54,3.24-11.58,3.98-13.94c0.38-1.2,1.43-4.78,2.27-8.18C272.58,17.33,272.2,16.51,271.44,16.02z"/>
                        <path d="M222.84,16.06c-0.27-0.53-0.77-0.85-1.42-0.89c-0.96,0-34.31,4.03-50.74,9.54c-16.59,5.56-33.79,15.68-36.96,20.36
                                c-3.11,4.58-3.26,12.79-2.64,15.28c0.6,2.42,2.55,5.75,6.09,7.18c13.08,5.29,32.56,14.11,34.68,15.16
                                c3.83,1.55,5.77,3.19,5.62,4.73c-0.3,3.2-18.25,16.69-49.9,32.7c-36.51,18.47-63.81,29.15-80.12,35.54
                                c-6.54,2.56-11.27,4.41-14.38,5.88c-1.37,0.65-1.53,1.54-1.5,2.01c0.04,0.66,0.49,1.27,1.1,1.48c0.14,0.05,16.07,5.04,27.29,6.9
                                c0.05,0.01,0.11,0.01,0.16,0.01c0.02,0,0.04,0,0.06,0c10.54-0.67,18.65-3.4,26.72-6.39c7-2.6,43.49-18.85,60.4-27.5
                                c29.85-15.25,47.67-27.54,54.47-37.56c1.89-2.84,5.74-18.42,3.31-26.81c-0.57-1.89-2.4-3.42-5.27-4.43
                                c-0.86-0.3-3.7-1.25-7.64-2.55c-11.29-3.74-34.66-11.49-36.92-12.76c-0.08-0.18-0.07-0.59-0.03-0.72
                                c12.52-6.84,32.86-13.37,60.46-19.42c0.26-0.06,0.48-0.21,0.63-0.44C216.4,33.24,224.43,19.18,222.84,16.06z"/>
                    </g>
                </svg>
            </router-link>  
        </div>
    </div>
</template>

<style lang="scss">
div.logo {
    cursor: pointer;
}
</style>
<script setup >
import BurgerMenu from '@/components/BurgerMenu.vue'
import { useRouter } from 'vue-router'
//import siraLogo from '@/assets/sira_logo.svg'
//

const router = useRouter()

// eslint-disable-next-line no-unused-vars
function goHome() {
    router.push('/')
}
</script>
